<template>
  <div class="contact">
    <BlackBanner />
    <div class="header"></div>
    <div class="wrap">
      <div class="intro">
        <p class="top">contact</p>
        <p class="middle">
          Learn what our Intelligent Processing Platform ™ can do for you.
        </p>
        <p class="bottom">
          Our team will be happy to answer your sales questions.<br />
          Fill out the form and we will contact you as soon as possible
        </p>
      </div>
    </div>
    <ContactUs />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ContactUs from "@/components/common/Contact.vue";
import BlackBanner from "@/components/common/BlackBanner.vue";

export default defineComponent({
  name: "Contact",
  components: {
    ContactUs,
    BlackBanner,
  },
  setup() {},
});
</script>

<style lang="less" scoped>
.contact {
  background: linear-gradient(
    180deg,
    #f2f4f9 100%,
    rgba(242, 244, 249, 0) 100%
  );
  .header {
    height: 639px;
    background: url("~@/assets/images/contact/contactBg.png");
    background-size: cover;
  }
  .wrap {
    margin: 0 auto;
    width: 750px;
    .intro {
      .top {
        width: 111px;
        height: 42px;
        opacity: 1;
        font-size: 36px;
        font-family: Abhaya Libre SemiBold;
        font-weight: 400;
        color: #646464;
        text-align: left;
        margin-bottom: 20px;
      }
      .middle {
        width: 750px;
        height: 144px;
        font-size: 48px;
        line-height: 48px;
        font-family: Abhaya Libre SemiBold;
        font-weight: 400;
        text-align: CENTER;
        color: #000000;
        margin: 0px;
      }
      .bottom {
        width: 679px;
        height: 68px;
        line-height: 34px;
        font-size: 24px;
        // font-family: .PingFang SC, .PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #000000;
        margin: 0px;
      }
    }
  }
}
</style>
